import React, { useState } from 'react';
import styles from './Searchbar.module.css';
import SearchTechniques from './SearchTechniques';

const Searchbar = () => {
  const [keyword, setKeyword] = useState();
  const [show, setShow] = useState(false);

  const handleChange = (e) => {
    setKeyword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (keyword === undefined || keyword === null || keyword === '') {
      window.location.href = `/search?q=""`;
    } else {
      window.location.href = `/search?q=${encodeURIComponent(keyword)}`;
    }
  };

  const openTechniques = () => {
    setShow(true);
  };

  const handleClick = () => {
    setShow(!show);
  };

  return (
    <>
      <div
        className={styles.searchbarContanier}
        style={{
          zIndex: show === true ? '1042' : '0',
        }}
      >
        <form onSubmit={handleSubmit} action='search' className={styles.searchForm}>
          <input
            type='search'
            name='q'
            className={styles.searchbar}
            placeholder='사건번호, 키워드, 법령 등을 검색해 보세요'
            autoComplete='off'
            onChange={handleChange}
            onClick={openTechniques}
          />
          <button type='submit' className={styles.searchbarBtn}>
            <img className={styles.searchImg} src='searchWhite.svg' alt='search' />
          </button>
        </form>
        <SearchTechniques show={show} onClick={handleClick} />
      </div>
      <div
        className={styles.techniquesListOpen}
        style={{
          display: show ? 'block' : 'none',
          zIndex: show ? '1041' : 'none',
          opacity: show ? '0.5' : '0',
        }}
        onClick={handleClick}
      ></div>
    </>
  );
};

export default Searchbar;
