import React, { useState, useEffect } from 'react';
import styles from './Update.module.css';
import axios from 'axios';

const Update = () => {
  const [contents, setContents] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const url = process.env.NEXT_PUBLIC_REST_API_URI_LEGACY + '/updates';
      try {
        const res = await axios.get(url);
        if (res.status === 200) {
          setContents(res.data[0]);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, []);

  return (
    <div className={styles.mainUpdates}>
      <a href={'update/' + contents.link}>{contents.description}</a>
    </div>
  );
};

export default Update;
