import React, { useEffect, useState } from 'react';
import styles from './StandardHeader.module.css';
import Hamburger from './Hamburger';
import Menu from './Menu';
import { useAuth } from '@src/providers/Auth';

const MainHeader = () => {
  const [open, setOpen] = useState(false);
  const { userInfo } = useAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    setIsAuthenticated(!!userInfo);
  }, [userInfo]);

  return (
    <header className={styles.header}>
      <div className={styles.logo}>
        <a href='/'>
          <img className={styles.logoImg} src='logo.svg' alt='logo' />
        </a>
        <font className={styles.betaTag}>beta</font>
      </div>
      <div className={styles.emptySearchbar}></div>
      <nav className={styles.navbar}>
        {
          <a className={styles.aiAtag} href='https://ai.legalengine.co.kr/crimesearch'>
            <div className={styles.ailink}> {`무죄판결 검색`}</div>
            <div className={styles.newTag}>{`new`}</div>
          </a>
        }
        {/*         <a href='/requestcase'>
          <div className={styles.navlink}>판례 요청</div>
        </a> */}
        <a href='/posts'>
          <div className={styles.navlink}>포스트</div>
        </a>
        <a href='/translator'>
          <div className={styles.navlink}>법률 번역</div>
        </a>
        {isAuthenticated ? (
          <a href='/user/profile/info'>
            <div className={styles.sign}>프로필</div>
          </a>
        ) : (
          <>
            <a href='/user/login'>
              <div className={styles.sign}>로그인</div>
            </a>
            <a href='/user/signup'>
              <div className={styles.signup}>회원가입</div>
            </a>
          </>
        )}
      </nav>
      <Hamburger open={open} setOpen={setOpen} />
      <Menu open={open} setOpen={setOpen} />
    </header>
  );
};

export default MainHeader;
