import React from 'react';
import styles from './ServiceIntro.module.css';
import { BsChevronCompactUp } from 'react-icons/bs';

const ServiceIntroduction = () => {
  return (
    <>
      <div className={styles.serviceSection}>
        <div className={styles.searchLink}>
          <a href='#search'>
            <BsChevronCompactUp size={40} />
            <div>검색하기</div>
          </a>
        </div>
        <div className={styles.serviceWarp}>
          <div className={styles.serviceCard}>
            <div className={styles.titleWarp}>
              <div className={styles.serviceHeadline}>태그</div>
              <div className={styles.serviceDescription}>
                <div>원하는 판례만 모아보세요.</div>
                <div>검색 페이지 오른쪽 아래에서 태그를 누르면 관련 판례만 모아 볼 수 있습니다.</div>
                <div>현재 형사 판례 태그를 제공 중이고, 다양한 태그를 준비 중에 있습니다.</div>
              </div>
            </div>
            <div className={styles.imgWarp}>
              <img className={styles.serviceImg} src='/tag.png' alt='tag' />
            </div>
          </div>
          <div className={styles.serviceCard}>
            <div className={styles.titleWarp}>
              <div className={styles.serviceHeadline}>미리보기</div>
              <div className={styles.serviceDescription}>
                <div>일일이 열어보지 않아도 검색 페이지에서 판결문 중요 부분을 미리 볼 수 있습니다.</div>
                <div>화살표 버튼을 누르면 검색한 검색어가 있는 부분을 보여드립니다.</div>
              </div>
            </div>
            <div className={styles.imgWarp}>
              <img className={styles.serviceImg} src='/carousel.gif' alt='carousel' />
            </div>
          </div>
          <div className={styles.serviceCard}>
            <div className={styles.titleWarp}>
              <div className={styles.serviceHeadline}>상세 검색</div>
              <div className={styles.serviceDescription}>
                <div>보다 정확도 높은 검색을 도와드립니다.</div>
                <div>
                  명령어를 입력하여, 원하지 않는 검색어를 제외하거나 반드시 들어가야 하는 검색어를 정할 수 있습니다.
                </div>
              </div>
            </div>
            <div className={styles.imgWarp}>
              <img className={styles.serviceImg} src='/advanced_search.png' alt='advanced_search' />
            </div>
          </div>
          <div className={styles.serviceCard}>
            <div className={styles.titleWarp}>
              <div className={styles.serviceHeadline}>법률 번역</div>
              <div className={styles.serviceDescription}>
                <div>법률 문서에 특화된 번역기를 제공합니다.</div>
                <div>
                  번역을 원하는 문장을 붙여 넣은 후, 번역 버튼을 누르면 리걸엔진 번역기와 구글 번역기의 번역 결과를 한
                  번에 확인할 수 있습니다.
                </div>
              </div>
            </div>
            <div className={styles.imgWarp}>
              <img className={styles.serviceImg} src='/translation.png' alt='translation' />
            </div>
          </div>
          <div className={styles.serviceCard}>
            <div className={styles.titleWarp}>
              <div className={styles.serviceHeadline}>영문 번역본 제공</div>
              <div className={styles.serviceDescription}>
                <div>
                  리걸엔진이 제공하는 모든 판례는 사건 번호 밑 ‘영어로 보기’ 버튼을 누르면 리걸엔진 번역기가 번역한 영문
                  번역본을 확인할 수 있습니다.
                </div>
              </div>
            </div>
            <div className={styles.imgWarp}>
              <img className={styles.serviceImg} src='/translated_case.png' alt='translated_case' />
            </div>
          </div>
          <div className={styles.serviceCard}>
            <div className={styles.titleWarp}>
              <div className={styles.serviceHeadline}>판결문 요청</div>
              <div className={styles.serviceDescription}>
                <div>
                  부분 판례(판결문 내용 중 일부분만 제공되는 판례)는 판결문 하단의 ‘판례 요청’ 버튼을 누르면 판례 전문을
                  받아볼 수 있습니다.
                </div>
                <div>등록되지 않은 판례도 요청 주시면 입수 후 알려드립니다.</div>
              </div>
            </div>
            <div className={styles.imgWarp}>
              <img className={styles.serviceImg} src='/case_request.png' alt='case_request' />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceIntroduction;
