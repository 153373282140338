import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import axios from 'axios';

import styles from './PostCard.module.css';

const caseReturnSize = 3;

const initPosts = Array(caseReturnSize).fill({
  title: null,
  contents: null,
  date: null,
  href: null,
});

const makeFullSentence = (description, maxLength = 80, margin = 5) => {
  // maxlength 근처에서 문장이 끝날 경우 해당 문장까지 보여줌
  if (description && description.length > maxLength) {
    let full;
    const divided = description.slice(maxLength - margin, maxLength + margin).split('.');
    if (divided.length > 1) {
      full = description.slice(0, maxLength - margin) + divided[0] + '.';
    } else {
      full = description.substr(0, maxLength) + '...';
    }
    description = full;
  }
  return description;
};

export const PostCardWrapper = () => {
  const [posts, setPosts] = useState(initPosts);

  useEffect(() => {
    const getPostsData = async () => {
      try {
        const res = await axios.post(
          process.env.NEXT_PUBLIC_REST_API_URI + '/api/search/posts',
          {
            query: '*',
            from: 0,
            size: caseReturnSize,
          },
          { withCredentials: true }
        );

        if (res.data && res.data.results.length > 0) {
          setPosts(res.data.results);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getPostsData();
  }, []);

  return (
    <div className={styles.postCards}>
      {posts.map(({ title, description, timestamp, link, href }, idx) => {
        description = makeFullSentence(description);
        href = `posts/${link}`;
        return <PostCard key={`_${idx}`} title={title} contents={description} date={timestamp} href={href} />;
      })}
    </div>
  );
};

export const PostCard = ({ title, contents, date, href, author }) => {
  if (!title) {
    // loading skeleton
    return (
      <div
        className={styles.postCard}
        // className="border border-blue-400 rounded-md flex flex-col flex-nowrap p-4 flex-1 space-y-4 md:mb-0 animate-pulse"
      >
        <div
          className='title'
          // className={'rounded bg-blue-100 w-full h-4'}
        />
        <div className={'bg-blue-100 rounded flex-1 w-full h-4'} />
        <div>
          <div className={'bg-blue-100 rounded w-full h-4'} />
          <Link href={href}>
            <a className='self-end w-full h-4'></a>
          </Link>
        </div>
      </div>
    );
  }
  return (
    <div
      // className="border border-blue-300 rounded-md flex flex-col flex-nowrap p-4 flex-1 space-y-4 md:mb-0"
      className={styles.postCard}
    >
      <div className={styles.title}>
        <Link href={href}>
          <a target='_blank'>{title}</a>
        </Link>
      </div>
      <div
        // className="rounded font-semibold text-lg"
        className={styles.contents}
      >
        {contents}
      </div>
      <div
        // className={'flex'}
        className={styles.bottom}
      >
        <span
          // className={'flex-1'}
          className={styles.date}
        >
          {date}
        </span>
        {/* <span className={styles.divider}>|</span> */}
        <span className={styles.author}>{author ?? '리걸엔진'}</span>
        <Link href={href}>
          <a
            target='_blank'
            // className="text-blue-500"
            className={styles.more}
          >
            더보기
          </a>
        </Link>
      </div>
    </div>
  );
};
