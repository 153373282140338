import React from 'react';
import styles from './Main.module.css';
import Footer from '@src/components/Base/Footer/Footer';
import Header from '@src/components/Base/Header/MainHeader';
import DefaultHead from '@src/components/Base/Head/DefaultHead';
import SchemaSitelinkHead from '@src/components/Base/Head/SchemaSitelinkHead';
import Meta from '@src/components/Base/Head/Meta';
import Searchbar from '@src/components/Main/Searchbar';
import ServiceIntro from '@src/components/Main/ServiceIntro';
import Update from '@src/components/Update/Update';
import { BsChevronCompactDown } from 'react-icons/bs';
import { PostCardWrapper } from '../components/Card/PostCard';
import _ from 'lodash';
import useScript from 'react-script-hook';

export default function HomePage() {
  // useScript({
  //   src: 'https://servedbyadbutler.com/app.js',
  //   onload: () => {
  //     var AdButler = window.AdButler || {};
  //     AdButler.ads = AdButler.ads || [];
  //     var abkw = window.abkw || '';
  //     var plc654582 = window.plc654582 || 0;
  //     (function () {
  //       var divs = document.querySelectorAll('.plc654582:not([id])');
  //       var div = divs[divs.length - 1];
  //       div.id = 'placement_654582_' + plc654582;
  //       AdButler.ads.push({
  //         handler: function (opt) {
  //           AdButler.register(186209, 654582, [300, 250], 'placement_654582_' + opt.place, opt);
  //         },
  //         opt: { place: plc654582++, keywords: abkw, domain: 'servedbyadbutler.com', click: 'CLICK_MACRO_PLACEHOLDER' },
  //       });
  //     })();
  //   },
  // });
  return (
    <div id={styles.main}>
      <SchemaSitelinkHead />
      <DefaultHead />
      <Meta
        title={`리걸엔진 | AI 판례 검색, 리걸 리서치`}
        description={`AI 리걸 리서치 - 국내 최다 340만 판례와 60만 유권해석을 한 번에 검색해 보세요`}
        url={`/`}
      />
      <Header />
      <main id='search' className={styles.mainBackground}>
        {/* <div
          className='plc654582'
          style={{
            position: 'fixed',
            top: '90px',
            left: '20px',
          }}
        /> */}
        <div className={styles.mainContent}>
          <div className={styles.mainWarp}>
            <img id={styles.mainLogoImg} src='/logo.svg' alt='logo' />
            {/* Search Section */}
            <div className={styles.searchField}>
              <Searchbar />
            </div>
            {/* Update Section */}
            <Update />
          </div>
          <PostCardWrapper />
          {/* serviceIntro Section */}
          <div className={styles.serviceLink}>
            <a href='#features'>
              <div>기능 보기</div>
              <div className={styles.serviceLinkText}>
                <BsChevronCompactDown size={40} />
              </div>
            </a>
          </div>
        </div>

        <div id='features' className={styles.onlyForPadding}></div>
        <ServiceIntro />
      </main>

      <Footer />
    </div>
  );
}
