import Head from 'next/head';

const SchemaSitelinkHead = (props) => {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: 'https://legalengine.co.kr',
    potentialAction: {
      '@type': 'SearchAction',
      target: 'https://legalengine.co.kr/search?q={search_term_string}',
      'query-input': 'required name=search_term_string',
    },
  };

  return (
    <Head>
      <script type='application/ld+json' dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}></script>
    </Head>
  );
};

export default SchemaSitelinkHead;
